<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    dark
    color="primary"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <v-list nav>
      <v-list-item>
        <v-img src="@/assets/logo.svg" contain height="80" />
      </v-list-item>
    </v-list>

    <v-list
      tile
      flat
      expand
      nav
      class="d-flex flex-column align-stretch justify-space-between"
      style="height: calc(100% - 96px)"
    >
      <div>
        <template v-for="(item, i) in computedItems">
          <template v-if="item.isfn">
            <base-item
              :key="`item-${i}`"
              :item="item"
              @click.native="logoutFn()"
            />
          </template>
          <template v-else>
            <base-item-group
              v-if="item.children"
              :key="`group-${i}`"
              :item="item"
            ></base-item-group>
            <base-item v-else :key="`item-${i}`" :item="item" />
          </template>
        </template>
      </div>
      <!-- <div>
        <template v-for="(item, i) in computedItemsBelow">
          <template v-if="item.isfn">
            <base-item :key="`item-${i}`" :item="item" @click.native="logoutFn()" />
          </template>
          <template v-else>
            <base-item-group v-if="item.children" :key="`group-${i}`" :item="item"></base-item-group>
            <base-item v-else :key="`item-${i}`" :item="item" />
          </template>
        </template>
      </div>-->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
/*eslint-disable */
export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    items: [
      {
        title: "Dashboard",
        // icon: "mdi-gauge",
        icon: "tachometer-alt",
        to: "/"
      },
      {
        title: "RFPs",
        // icon: "mdi-file-document-outline",
        icon: "file-alt",
        to: "/rfp"
      },
      {
        title: "Completed RFPs",
        // icon: "mdi-account-tie",
        icon: "list-alt",
        to: "/completed-rfp"
      },
      {
        title: "CO Subscribers",
        // icon: "mdi-account-tie",
        icon: "user-tie",
        to: "/organiser-subscribers"
      },
      {
        title: "Venue Subscribers",
        // icon: "mdi-map",
        icon: "building",
        to: "/venue-subscribers"
      },
      {
        title: "City Subscribers",
        // icon: "mdi-office-building",
        icon: "map-marked-alt",
        to: "/city-subscribers"
      },
      {
        title: "Cities",
        icon: "city",
        to: "/cities"
      },
      {
        title: "Country Subscribers",
        icon: "globe",
        to: "/country-subscribers"
      },
{
        title: "Country",
        icon: "globe-americas",
        to: "/countries"
      },
      {
        title: "Transactions",
        // icon: "mdi-shield-account",
        icon: "hand-holding-usd",
        to: "/transactions"
      },
      {
        title: "Bookings",
        // icon: "mdi-shield-account",
        icon: "file-signature",
        to: "/bookings"
      },
      {
        title: "Add-on Payments",
        // icon: "mdi-shield-account",
        icon: "address-book",
        to: "/add-on-payment"
      },
      {
        title: "City Payments",
        // icon: "mdi-shield-account",
        icon: "money-check-alt",
        to: "/city-payments"
      },
      {
        title: "Call for Tenders",
        // icon: "mdi-shield-account",
        icon: "folder-open",
        to: "/call-for-tenders"
      },
      {
        title: "Administrators",
        // icon: "mdi-shield-account",
        icon: "user-shield",
        to: "/admins"
      },

      // {
      //   title: "Stripe Subscription Plane",
      //   // icon: "mdi-shield-account",
      //   icon: "table",
      //   to: "/subscribePlan"
      // },
      {
        title: "Reviews",
        // icon: "mdi-shield-account",
        icon: "file-signature",
        to: "/reviews"
      },
      {
        title: "Partner Promotions",
        // icon: "mdi-shield-account",
        icon: "users",
        to: "/Partner-Promotions"
      },
      {
        title: "Upload Manuals",
        // icon: "mdi-shield-account",
        icon: "file",
        to: "/organiser-hybrid-manual"
      },
      {
        title: "Contacts",
        // icon: "mdi-shield-account",
        icon: "phone",
        to: "/contacts"
      },
      {
        title: "Settings",
        // icon: "mdi-cogs",
        icon: "cogs",
        to: "/settings"
      },
      {
        title: "Logout",
        // icon: "mdi-logout-variant",
        icon: "sign-out-alt",
        to: "/login",
        isfn: true
      }
    ]
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      }
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    computedItemsBelow() {
      return this.itemsBelow.map(this.mapItem);
    }
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      };
    },
    logoutFn() {
      localStorage.clear();
      location.replace("/login");
    }
  }
};
</script>
<style>
#core-navigation-drawer .v-navigation-drawer__content {
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.core-navigation-drawer .v-navigation-drawer__content::-webkit-scrollbar {
  width: 0.5rem;
}
.core-navigation-drawer .v-navigation-drawer__content::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #f0f0f0;
}
.core-navigation-drawer .v-navigation-drawer__content::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
</style>
<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
      margin-right: 24px
      margin-left: 12px !important

      +rtl()
      margin-left: 24px
      margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
      padding-left: 8px

      +rtl()
      padding-right: 8px

    .v-list-group__header
      +ltr()
      padding-right: 0

      +rtl()
      padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
        margin-right: 8px

        +rtl()
        margin-left: 8px
</style>
